import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslatePipe} from "@ngx-translate/core";
import {IconOptions, SelectOptionModel} from "@app/@atl/modules/form-controls/select/select-option.model";
import {isArray, isEqual} from "lodash";

@Component({
    selector: 'lta-select',
    templateUrl: 'select.component.html',
    styleUrls: ['select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild('wrapper') wrapper: ElementRef
    @ViewChild('input') input: ElementRef<HTMLSpanElement>
    @ViewChild('selections', {static: false}) selections: ElementRef<HTMLSpanElement>
    @Input() offsetBottom: number = 100
    @Input() theme: 'dark' | 'alert' | 'white' | 'alert-dark' | '' = ''
    @Input() position: 'top' | 'bottom' | 'bottom-full' | 'dynamic' = 'bottom'
    @Input() withCancel: boolean = false
    @Input() placeholder: string = this.translatePipe.transform('selectValue');
    @Input() isShowValue: boolean = true
    @HostBinding('class.expanded') expanded = false;
    @Input() icon: string;
    @Input() iconOptions: IconOptions;
    @Input() skipSelection = false;
    @Input() label;
    @Input() value: (string | number | object) | Array<string | number | object> | null = null;
    @Input() disabled: boolean = false;
    @Input() readonly: boolean = false;
    @Input() hideAfterSelect: boolean = true;
    @Input() multiSelect: boolean = false;
    @Output() onClickReturn: EventEmitter<void> = new EventEmitter<void>();
    private offsetTop: number;
    private isClickInside = false;

    constructor(private translatePipe: TranslatePipe, private cd: ChangeDetectorRef) {
    }

    public _currOption: SelectOptionModel | Array<SelectOptionModel> = null;

    public get currOption(): SelectOptionModel {
        if (!this.multiSelect) {
            return this._currOption as SelectOptionModel
        }
    }

    public get currOptions(): SelectOptionModel[] {
        if (this.multiSelect) {
            return this._currOption as SelectOptionModel[]
        }
    }

    public get maxHeight(): string {
        if (this.position === 'dynamic') {
            if (this.freeSpace < 100) {
                return 'max-content'
            }
            return `${this.freeSpace}px`
        }

        if (this.position === 'bottom') {
            return `${this.freeSpace}px`
        }
        return 'max-content'
    }

    public get freeSpace() {
        return window.innerHeight - this.offsetTop - this.offsetBottom
    }

    public get menuPosition(): string {
        if (this.position === 'dynamic') {
            if (this.freeSpace < 100) {
                return 'top'
            }
            return `bottom`

        }
        return this.position
    }

    @HostBinding('class.disabled') get isDisabled() {
        return this.disabled;
    }

    _selectOptions: SelectOptionModel[] = [];

    get selectOptions() {
        return this._selectOptions;
    }

    @Input() set selectOptions(options: SelectOptionModel[]) {
        this._selectOptions = options?.map(op => ({
            ...op,
            visibility: op.hasOwnProperty('visibility') ? op.visibility : true
        }));

        if (options?.length && this.value) {
            this.writeValue(this.value);
        }
    }

    private _isLoading: boolean = false;

    get isLoading(): boolean {
        return this._isLoading
    }

    @Input() set isLoading(loading: boolean) {
        if (!loading) {
            setTimeout(() => {
                this._isLoading = loading
                this.cd.markForCheck()
            })
        } else {
            this._isLoading = loading
            this.expanded = false
        }
    }

    ngAfterViewInit() {
        this.input.nativeElement.style.transition = 'all 0.3s ease-in-out'
    }

    public trackBy(index: number, item: SelectOptionModel) {
        return item.value
    }

    public calcMaxHeight() {
        if (this.wrapper) {
            const {top, height} = this.wrapper.nativeElement.getBoundingClientRect();
            if (top && height) {
                this.offsetTop = top + height;
            }
        }
    }

    @HostListener('click')
    clickHost(): void {
        this.isClickInside = true;
    }

    @HostListener('document:click')
    onDocumentClick() {
        if (this.isDisabled || !this.expanded) {
            return;
        }
        if (!this.isClickInside) {
            this.expanded = false;
        }
        this.isClickInside = false;
    }

    writeValue(obj: (string | number | object) | Array<string | number | object>): void {
        this.value = obj;

        if (obj === null) {
            this._currOption = null
            this.icon = null
        } else if (isArray(obj) && this.multiSelect) {
            this._currOption = this.selectOptions.filter(opt => obj.includes(opt.value))

        } else {
            for (let i = 0; i < this.selectOptions?.length; i++) {
                if (isEqual(this.value, this.selectOptions[i].value)) {
                    this._currOption = this.selectOptions[i];
                    break;
                }
            }

            if (this.currOption?.icon) {
                this.icon = this.currOption.icon;
                if (this.currOption.iconOptions) {
                    this.iconOptions = this.currOption.iconOptions
                }
            } else {
                this.icon = null
                this.iconOptions = null
            }
        }
        this.cd.markForCheck()
    }

    updateValue(obj: (string | number | object) | Array<string | number | object> | null) {
        if (this.skipSelection) {
            this.onChange(obj);
            return
        }
        this.value = obj;

        if (isArray(obj) && this.multiSelect) {
            this._currOption = this.selectOptions.filter(opt => obj.includes(opt.value))
        } else {
            for (let i = 0; i < this.selectOptions.length; i++) {
                if (isEqual(this.value, this.selectOptions[i].value)) {
                    this._currOption = this.selectOptions[i];
                    break;
                }
            }

            if (this.currOption?.icon) {
                this.icon = this.currOption.icon;
                if (this.currOption.iconOptions) {
                    this.iconOptions = this.currOption.iconOptions
                }
            } else {
                this.icon = null
                this.iconOptions = null
            }
        }

        this.onChange(this.value);
        this.onTouched();
    }

    resetValue() {
        this.value = null;
        this._currOption = null;
        this.icon = null;
        this.iconOptions = null;
        this.onChange(null);
        this.onTouched();
    }

    optionSelect(option: SelectOptionModel) {
        if (this.isDisabled) {
            return;
        }
        if (this.multiSelect) {
            const selected = isArray(this.value) ? this.value : []
            if (selected.includes(option.value)) {
                this.updateValue(selected.filter(v => v !== option.value))
            } else {
                this.updateValue([...selected, option.value])
            }
        } else {
            this.updateValue(option.value);
        }

        if (this.hideAfterSelect) {
            this.expanded = false;
        }
    }

    isOptionSelected(option: SelectOptionModel) {
        return this.currOption && isEqual(option.value, this.currOption.value);
    }

    toggleSelect() {
        if (this.isDisabled) {
            return;
        }
        this.expanded = !this.expanded;
    }

    isValueSelected() {
        return this.currOption !== null && this.currOption?.value !== null;
    }

    onChange = (value: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
