export interface ILtaComponent {
    id: number;
    key: string;
    file_key: string;
    name: string;
    node_id: number;
    thumbnail_url: string;
    svg: string;
    tm_create: number;
    tm_update: number;
    script: string;
    svg_elements: ISvgElement[];
}

export interface ISvgElement {
    id: string;
    name: string;
    path: number[];
}

export interface ISvgElementTree extends ISvgElement {
    children?: ISvgElementTree[]
}

export interface IEditComponent {
    script: string
}
