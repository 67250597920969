import {Injectable} from "@angular/core"
import {INewPasswordForm, IUser} from "@app/@atl/administration/users/interfaces"
import {LocalStorageService} from "@app/@atl/shared/services"
import {BehaviorSubject, Observable, of} from "rxjs"
import {catchError, tap} from "rxjs/operators"
import {AuthHttpService} from "./auth-http.service"
import {IRole} from "@atl/admin/roles/interfaces";
import {AccessPermissionsAbstract} from "@atl/authorization/services/access-permissions.abstract";

export const USER = 'USER'

@Injectable({
    providedIn: 'root'
})
export class UserService extends AccessPermissionsAbstract {
    private userSubject: BehaviorSubject<IUser> = new BehaviorSubject(null)
    public user$: Observable<IUser> = this.userSubject.asObservable()
    private userPictureSubject: BehaviorSubject<string | ArrayBuffer> = new BehaviorSubject<string | ArrayBuffer>(undefined)
    public userPicture$: Observable<string | ArrayBuffer> = this.userPictureSubject.asObservable()

    constructor(
        private authHttp: AuthHttpService,
        private storage: LocalStorageService,
    ) {
        super()
    }


    public getUser(): IUser | null {
        return this.storage.getItem(USER, true)
    }

    public updateUserRole(role: IRole) {
        const user = this.getUser()
        user.role = role
        this.setUser(user)
    }

    public setUser(user: IUser) {
        this.userSubject.next(user)
        this.setAccess(user?.role?.permissions)
        this.storeUser(user)
    }

    public setUserPicture(image: string | ArrayBuffer) {
        this.userPictureSubject.next(image)
    }

    public updateCurrentUser(user: Partial<IUser>) {
        const {role} = this.userSubject.value
        return this.authHttp.updateCurrentUser(user)
            .pipe(tap(value => this.setUser({...value, role})))
    }

    public updateCurrentUserPassword(passwords: INewPasswordForm) {
        return this.authHttp.updateCurrentUserPassword(passwords)
    }

    public setCurrentUserPicture(picture: string | ArrayBuffer): Observable<string> {
        return this.authHttp.setCurrentUserPicture(picture)
            .pipe(tap(value => this.setUserPicture(picture)))
    }

    public deleteCurrentUserPicture(): Observable<string> {
        return this.authHttp.deleteCurrentUserPicture()
            .pipe(tap(value => this.setUserPicture(null)))
    }

    public requestCurrentUser(): Observable<IUser> {
        // Will return null if no tokens
        return this.authHttp.getCurrentUser()
            .pipe(
                tap(user => {
                    this.setUser(user)
                }),
                catchError(error => {
                    console.error(error.error)
                    return of(null)
                })
            )
            .pipe(
                tap(user => {
                    if (user && user.avatar_id) {
                        this.authHttp.getCurrentUserPicture().pipe(catchError((err, caught) => {
                            return of(null)
                        })).subscribe(value => this.userPictureSubject.next(value?.avatar))
                    } else {
                        this.userPictureSubject.next(null)
                    }
                })
            )
    }

    private storeUser(user: IUser) {
        this.storage.setItem(USER, user)
    }
}
