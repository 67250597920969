import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '@environment/environment';
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {ClipboardModule} from 'ngx-clipboard';
import {ColorPickerModule} from 'ngx-color-picker';
import {AppComponent} from './app.component';
import {HttpLoaderFactory} from './app.settings';
import {AppRoutingModule} from './routing/app-routing.module';
import {WebsocketModule} from '@atl/websocket';
import {AuthTokenInterceptor} from '@atl/interceptors/auth-token.interceptor'
import {ErrorInterceptor} from '@atl/interceptors/error-interceptor'
import {NanosecondsToMillisecondsPipe} from "@atl/pipes/nanoseconds-to-milliseconds/nanoseconds-to-milliseconds.pipe";
import {LtaDatePipe} from "@atl/pipes/lta-date/lta-date.pipe";
import {FilterPipe} from "@atl/pipes/filter/filter.pipe";
import {FlashMessagesModule} from "@atl/modules/flash-messages";
import {ReactiveFormsModule} from '@angular/forms';
import {TreeModule} from "@ali-hm/angular-tree-component";

registerLocaleData(localeRu, 'ru');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ClipboardModule,
        ColorPickerModule,
        WebsocketModule.config({
            url: environment.appSettings.websocketUrl
        }),
        FlashMessagesModule,
        ReactiveFormsModule,
        TreeModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {provide: LOCALE_ID, useValue: 'ru-ru'},
        HttpClient,
        TranslatePipe,
        NanosecondsToMillisecondsPipe,
        DatePipe,
        LtaDatePipe,
        FilterPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
