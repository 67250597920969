import {HttpClient} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {INewPasswordForm, IUser, IUserPicture} from "@app/@atl/administration/users/interfaces"
import {BaseHttp} from "@app/@atl/shared/abstract"
import {Observable} from "rxjs"
import {AuthCredentials, AuthTokens} from "../interfaces"

@Injectable({
    providedIn: 'root'
})
export class AuthHttpService extends BaseHttp {

    constructor(private http: HttpClient) {
        super()
    }

    login(credentials: AuthCredentials): Observable<AuthTokens> {
        const url = `${this.apiRoot}/auth/login`;
        return this.http.post<AuthTokens>(url, credentials)
    }

    logout(tokens: AuthTokens): Observable<null> {
        const url = `${this.apiRoot}/auth/logout`;
        return this.http.delete<null>(url, {
            body: tokens
        })
    }

    refreshTokens(tokens: AuthTokens): Observable<AuthTokens> {
        const url = `${this.apiRoot}/auth/refresh`;
        return this.http.put<AuthTokens>(url, tokens)
    }

    getCurrentUser(): Observable<IUser> {
        const url = `${this.apiRoot}/self`;
        return this.http.get<IUser>(url)
    }

    getCurrentUserPicture(): Observable<IUserPicture> {
        const url = `${this.apiRoot}/self/avatar`;
        return this.http.get<IUserPicture>(url)
    }

    updateCurrentUser(user: Partial<IUser>): Observable<IUser> {
        const url = `${this.apiRoot}/self`;
        return this.http.put<IUser>(url, user, {withCredentials: true})
    }

    updateCurrentUserPassword(passwords: INewPasswordForm): Observable<string> {
        const url = `${this.apiRoot}/self/password`;
        return this.http.put<string>(url, passwords, {withCredentials: true})
    }

    setCurrentUserPicture(picture: string | ArrayBuffer): Observable<string> {
        const url = `${this.apiRoot}/self/avatar`;
        return this.http.post<string>(url, {avatar: picture}, {withCredentials: true})
    }

    deleteCurrentUserPicture(): Observable<string> {
        const url = `${this.apiRoot}/self/avatar`;
        return this.http.delete<string>(url, {withCredentials: true})
    }
}
