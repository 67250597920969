import {isEqual} from "lodash";
import {Observable, Subject} from "rxjs";
import {IChangeObject} from "@atl/modules/change-detector/interfaces/change-detector.interface";
import {SaveDialogComponent} from "@atl/modules/modals/save-dialog/save-dialog.component";
import {untilDestroyed} from "@ngneat/until-destroy";
import {ModalService} from "@atl/shared/services/modal.service";
import {SaveDialogModule} from "@atl/modules/modals/save-dialog/save-dialog.module";

export abstract class ChangeDetectorAbstract {
    public confirmUnSaveChanges = new Subject<boolean>()
    protected trackChanges: IChangeObject<any>[] = []
    protected modalService: ModalService
    protected onCancel: (confirm: boolean) => void
    protected onSave: () => Observable<boolean> | void

    public isUnchanged(): boolean {
        let unchanged = true
        this.trackChanges.forEach(c => {
            if (!this.checkChanges(c)) {
                unchanged = false
            }
        })
        return unchanged
    }

    public checkChanges(ch: IChangeObject<any>) {
        return isEqual(ch.getOriginal(), ch.getCopy())
    }

    public canDeactivate(): boolean | Observable<boolean> {
        const canDeactivate = this.isUnchanged()
        !canDeactivate && this.createSaveDialog()
        return canDeactivate ? true : this.confirmUnSaveChanges.asObservable()
    }

    public createSaveDialog() {
        const modalId = 'save'
        const saveDialogRef = this.modalService.create<SaveDialogComponent>(modalId, SaveDialogComponent, {module: SaveDialogModule})
        if (this.canSave) {
            saveDialogRef.instance.canSave = this.canSave()
        }
        saveDialogRef.instance.onCancel.pipe(untilDestroyed(saveDialogRef.instance)).subscribe(() => {
            this.onCancel(false)
            this.modalService.remove(modalId)
        })
        saveDialogRef.instance.onDontSave.pipe(untilDestroyed(saveDialogRef.instance)).subscribe(() => {
            this.onCancel(true)
            this.modalService.remove(modalId)
        })
        saveDialogRef.instance.onSave.pipe(untilDestroyed(saveDialogRef.instance)).subscribe(() => {
            const observable = this.onSave()
            if (!observable) {
                this.modalService.remove(modalId)
                return
            }
            saveDialogRef.instance.state = 'SAVING'
            observable.subscribe(value => {
                saveDialogRef.instance.state = 'SAVED'
                value && this.modalService.remove(modalId)
            })
        })
    }

    protected canSave?(): boolean
}
