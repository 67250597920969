/*
 * WhereShock
 * ----------
 * Version: 1.0
 * Date: 6/17/19 5:15 PM
 *
 * Cloud software platform of a distributed control system for service platforms "WhereShock"
 *
 * Authors
 * ----------
 * Podolny Vadim Pavlovich
 * Semenov Roman Alexandrovich
 * Parfilov Ivan Vasilevich
 * Somov Dmitry Andreevich
 *
 * Облачная программная платформа распределенной системы управления для построения автоматизированных систем управления технологическими процессами и сервисных платформ индустриального интернета вещей «Вершок»
 */

import {NgModule} from '@angular/core';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {AdminLayoutComponent} from '@atl/administration/admin/components/admin-layout/admin-layout.component';
import {AuthGuard, LoginGuard} from '@app/@atl/authorization/guards'
import {PageNotFoundComponent} from '@app/@atl/modules/page-not-found/page-not-found.component';
import {CustomRouteReuseStrategy} from "@app/routing/custom-route-reuse-strategy";

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('../@atl/authorization/authorization.module').then(m => m.default),
        canActivate: [LoginGuard],
    },
    {
        component: AdminLayoutComponent,
        path: 'conf',
        loadChildren: () => import('../@atl/administration/admin/admin.module').then(m => m.default),
        canLoad: [AuthGuard],

    },
    {
        path: 'not-found',
        component: PageNotFoundComponent,
        loadChildren: () => import('../@atl/modules/page-not-found/page-not-found.module').then(m => m.default)
    },
    {
        path: '',
        loadChildren: () => import('../@atl/main/main.module').then(m => m.default),
        canLoad: [AuthGuard]
    },
    {
        path: '**',
        loadChildren: () => import('../@atl/modules/page-not-found/page-not-found.module').then(m => m.default),
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [{
        provide: RouteReuseStrategy,
        useClass: CustomRouteReuseStrategy
    }]
})
export class AppRoutingModule {
}
