import {Injectable} from '@angular/core';
import {BaseHttp} from '@atl/shared/abstract';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {UntilDestroy} from "@ngneat/until-destroy";
import {nanoToMilliseconds} from "@atl/shared/utils";

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class TimeService extends BaseHttp {
    public serverTimeDelta: number
    public serverTimezone: string;

    constructor(private http: HttpClient) {
        super();
    }

    public initTime() {
        const url = `${this.apiRoot}/time/current`;
        return this.http.get<{ time: number, zone: { offset: number, name: string } }>(url)
            .pipe(
                map(value => {
                    this.serverTimeDelta = moment(nanoToMilliseconds(value.time)).diff(new Date());
                    this.serverTimezone = value.zone.name;
                    moment.tz.setDefault(value.zone.name)
                })
            )
    }
}
