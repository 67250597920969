import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from '@atl/shared/services/local-storage.service';
import {environment} from '@environment/environment';

export interface LangData {
    title: string;
    locale: string;
    disabled: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LangService {
    currentLang: LangData;
    languages: LangData[] = [
        {
            title: 'Русский',
            locale: 'ru',
            disabled: false
        },
        {
            title: 'English',
            locale: 'en',
            disabled: false
        },
    ];

    constructor(
        private translate: TranslateService,
        private storage: LocalStorageService
    ) {
    }

    initLang() {
        const lang = this.storage.getItem('language');
        const locale = lang ? lang.locale : environment.defaultLang;
        this.translate.use(locale);
        this.currentLang = this.languages.find(l => l.locale === locale);
    }

    useLocale(locale: string) {
        this.translate.use(locale);
    }

    saveLang(lang: LangData) {
        if (!lang.disabled) {
            this.storage.setItem('language', lang);
            this.currentLang = lang;
        }
    }

    getCurrentLang() {
        return this.currentLang?.locale;
    }
}
