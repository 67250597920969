import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {Observable, of, throwError} from "rxjs"
import {catchError, delay, mergeMap, take, tap} from "rxjs/operators"
import {AuthService,} from "../authorization/services"
import {RefreshmentStatus} from "@atl/authorization/interfaces";


@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    private SELF_ROUTE = '/self';

    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokens = this.authService.getTokens()
        if (this.authService.isLoggedIn()) {
            req = this.addToken(req, tokens.access_token)
        }

        if (!this.authService.isLoggedIn() && req.url.includes(this.SELF_ROUTE)) {
            return of(new HttpResponse({body: null}))
        }

        return next.handle(req).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(req, next)
            } else {
                return throwError(error)
            }
        }))
    }

    private addToken(req: HttpRequest<any>, token: string) {
        return req.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        const refreshmentStatus: RefreshmentStatus = this.authService.getRefreshmentStatus() || RefreshmentStatus.False
        if (refreshmentStatus === RefreshmentStatus.False) {
            this.authService.setRefreshmentStatus(RefreshmentStatus.Active)
            const REFRESHMENT_ID = Date.now()
            this.authService.setRefreshmentId(REFRESHMENT_ID)
            return of(null)
                .pipe(
                    delay(500),
                    mergeMap(() => {
                        if (REFRESHMENT_ID === this.authService.getRefreshmentId()) {
                            console.log('start token refreshing by this tab')
                            return this.authService.refreshTokens().pipe(
                                tap((tokens) => {
                                    console.log('token refreshed by this tab')
                                    setTimeout(() => {
                                        this.authService.setRefreshmentStatus(RefreshmentStatus.False)
                                    }, 5000)
                                    this.authService.sendTokensToOtherTabs(tokens)
                                }),
                                catchError((err, caught) => {
                                    this.authService.setRefreshmentStatus(RefreshmentStatus.Error)
                                    this.authService.sendLogoutRequestToOtherTabs()
                                    return this.authService.logout(true)
                                }),
                                mergeMap((tokens) => {
                                    return next.handle(this.addToken(req, tokens.access_token))
                                })
                            )
                        } else {
                            console.log('wait for new token from other tab')
                            return this.waitForTokensFromOtherRequest(req, next)
                        }
                    })
                )
        } else if (refreshmentStatus === RefreshmentStatus.Error) {
            this.authService.localLogout(true)
            return of(null)
        } else if (refreshmentStatus === RefreshmentStatus.Active) {
            console.log('wait for new token from other tab')
            return this.waitForTokensFromOtherRequest(req, next)
        }
    }

    waitForTokensFromOtherRequest(req: HttpRequest<any>, next: HttpHandler) {
        const observable = this.authService.isRefreshmentFinished$
        return observable
            .pipe(
                take(1),
                catchError(() => {
                    this.authService.localLogout(true)
                    return of(null)
                }),
                tap(() => console.log('token refreshed by other tab')),
                mergeMap((tokens) => {
                    return next.handle(this.addToken(req, tokens.access_token))
                })
            )
    }
}
