import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {MenuService} from './menu.service';
import {UserService} from "@atl/authorization/services/user.service";
import {Observable} from 'rxjs';
import {IUser} from '@app/@atl/administration/users/interfaces';

@Component({
    selector: 'lta-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
    providers: [
        MenuService
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
    public user$: Observable<IUser> = this.userService.user$
    public userPicture$: Observable<ArrayBuffer | string> = this.userService.userPicture$

    constructor(public menuService: MenuService, private router: Router, public userService: UserService) {
    }

    ngOnInit(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                if (this.menuService.isOpen) {
                    this.menuService.closeMainMenu();
                }
            });
    }

    isOpen(target: string): boolean {
        return this.menuService.isOpened(target);
    }
}
