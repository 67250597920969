export interface IModelBase {
    id: number;
    name: string;
    descr: string;
    type_id: Types;

}

export interface IModel extends IModelBase {
    tm_create: number;
    tm_update: number;
    components: any;
    children: IModelChild[];
    user_id?: number;
}

export interface IModelTree extends IModelBase {
    path: string
    model_childs_path: string
    children: IModelTree[];
}

export interface IModelChild {
    id: number
    model_id: number
    model_name?: string
    type_id?: number
    child_descr: string
    child_name: string
    parent_id?: number //Added locally
}

export enum Types {
    'Bool' = 1,
    'Float' = 2,
    'String' = 3,
    'Dir' = 4,
    'Int' = 5,
}

export interface IType {
    id: number,
    name: string
}

export interface IModelComponent {
    component: {
        id: number;
        name: string;
        svg: string;
        tm_create: number;
        tm_update: number;
        script: string;
    };
    script: string;
}

export interface IModelShort {
    id: number;
    name: string;
    script?
}

export interface ICreateModel {
    name: string;
    descr: string;
    type_id: number;
    childs: IModelChild[]
}

export interface IUpdateModel {
    name?: string;
    descr?: string;
}

export interface ICreateModelChild {
    model_id: number
    child_name: string
    child_descr: string

}

export interface IUpdateModelChild {
    name?: string
    descr?: string,
}

export interface ISetModelComponent {
    script: string
}

export function isModelChild(object?: any): object is IModelChild {
    return object ? 'child_name' in object : false;
}

export function isModel(object?: any): object is IModel {
    return object ? 'name' in object : false;
}

export interface IModelPageParamMap {
    modelId: number,
    childId: number,
    componentId: number
}

export type ModelResponse = {
    model: IModel;
    paramMap: IModelPageParamMap;
    components: IModelComponent[];
} | {
    childId: number;
    children: IModelChild[];
    paramMap: IModelPageParamMap;
    components: IModelComponent[];
}


export interface IModelPathItem {
    id: number;
    name: string;
    type?: number;
    descr?: string;
}
