export interface IDriver {
    id: number | null,
    name: string,
    node_id: number,
    descr: string,
    driver_status: DriverStatus,
    driver_info: IDriverInfo,
    tm_change_run_status: number
}

export interface IDriverDetailed extends IDriver {
    run_setting: IDriverRunSetting
    driver_info: IDriverInfoDetailed
}

export interface IDriverSaveData {
    name?: string;
    descr?: string;
    run_setting?: IDriverRunSetting;
    driver_info?: IDriverInfoDetailed;
}

export interface IDriverInfo {
    type_name: string;
    type_id: number,
    has_tree?: boolean
}

export interface IDriverInfoDetailed extends IDriverInfo {
    has_tree: boolean,
    settings: IDriverSetting[],
}

export interface IDriverSetting {
    id: number,
    setting_id: number,
    name: string,
    value: string
}

export interface DriverStatus {
    conn_status: DriverConnection;
    err_read: number;
    err_sub: number;
    err_write: number;
    node_id: number;
    protocol_conn_status: DriverProtocolConnection;
    process_status: DriverStarted;
    process_status_error_msg: string;
    process_status_tm: number;
}

export enum DriverStarted {
    RUN_STATUS_STOPPED = 0,
    RUN_STATUS_STARTED_CHECK = 1,
    RUN_STATUS_STARTED = 2,
    RUN_STATUS_ERROR = 3,
}

export enum DriverConnection {
    DRIVER_CONN_STATUS_CONNECTED = 0,
    DRIVER_CONN_STATUS_NOT_CONNECTED = 1,
    DRIVER_CONN_STATUS_ERROR = 2
}

export enum DriverProtocolConnection {
    DRIVER_CONN_STATUS_CONNECTED = 0,
    DRIVER_CONN_STATUS_NOT_CONNECTED = 1,
    DRIVER_CONN_STATUS_ERROR = 2
}

export enum DriverActions {
    START,
    STOP,
    RESTART
}

export enum DriverTreeTypes {
    SERVER_TYPE_UNKNOWN = 0,
    SERVER_TYPE_FLOAT64 = 1,
    SERVER_TYPE_BOOL = 2,
    SERVER_TYPE_STRING = 3
}

export interface IDriverTree {
    has_tree: boolean;
    tree: IDriverTreeItem[];
}

export interface IDriverTreeItem {
    childs?: IDriverTreeItem[],
    id: number,
    id_lng: string,
    name: string,
    object_id: string,
    type: DriverTreeTypes,
    var: boolean,
    mapping?: IDriverMapping[]
}

export interface IDriverMapping {
    enabled: boolean,
    id: number,
    obj_id: string,
    obj_name_lng: string,
    obj_name_shrt: string,
    write_flag: boolean,
    error?: IDriverError,
}

export interface IDriverError {
    error_msg: string,
    error_type: number
}

export interface IDriverAttachmentInfo {
    id: number;
    name: string;
    size: number;
}

export interface IDriverRunSetting {
    bind_to_default_node: boolean,
    default_node_id: number,
    need_run: boolean
}

export interface IDriverHasTree {
    has_tree: boolean
}

export interface IDriverIntegration {
    id: number
    login: string
    password: string
    timeout: number
    host: string
}

export enum DriverIntegrations {
    Trassir = 'trassir',
    FindFace = 'ff',
    Perco = 'perco',
    Zabbix = 'zabbix',
    Orion = 'orion'
}

export enum DriverIntegrationIconColors {
    Trassir = '#D93126',
    FindFace = '#000000',
    Perco = '#264396',
    Zabbix = '#B32B28',
    Orion = '#006CB5'
}

export interface IDriverAccount {
    id: number
    login: string
    password: string
}
