import {IPermission} from "@atl/admin/roles/interfaces";

export abstract class AccessPermissionsAbstract {
    public canAccessRole: boolean = false
    public canAccessUser: boolean = false
    public canAccessModel: boolean = false
    public canAccessObject: boolean = false
    public canAccessComponent: boolean = false
    public canAccessVideoScreen: boolean = false
    public canAccessEvent: boolean = false
    public canAccessCluster: boolean = false
    public canAccessDriver: boolean = false

    private readonly rolePermissionID = 4
    private readonly userPermissionID = 2
    private readonly modelPermissionID = 14
    private readonly objectPermissionID = 12
    private readonly componentPermissionID = 16
    private readonly videoScreenPermissionID = 18
    private readonly eventPermissionID = 25
    private readonly clusterPermissionID = 26
    private readonly driverPermissionID = 28

    public setAccess(permissions: IPermission[]) {
        if (!permissions) return

        permissions.forEach(permission => {
            switch (permission.id) {
                case this.rolePermissionID:
                    this.canAccessRole = true
                    break
                case this.userPermissionID:
                    this.canAccessUser = true
                    break
                case this.modelPermissionID:
                    this.canAccessModel = true
                    break
                case this.objectPermissionID:
                    this.canAccessObject = true
                    break
                case this.componentPermissionID:
                    this.canAccessComponent = true
                    break
                case this.videoScreenPermissionID:
                    this.canAccessVideoScreen = true
                    break
                case this.eventPermissionID:
                    this.canAccessEvent = true
                    break
                case this.clusterPermissionID:
                    this.canAccessCluster = true
                    break
                case this.driverPermissionID:
                    this.canAccessDriver = true
                    break
            }
        })
    }
}
