<div
        #wrapper
        [ngClass]="theme"
        class="wrapper"
>
    <span
            *ngIf="label"
            class="label"
    >{{ label }}</span>
    <span class="input-wrapper">
        <span
                [ngClass]="{ open: expanded, disabled: disabled }"
                class="select"
        >
            <span
                    #input
                    (click)="!readonly && toggleSelect(); !readonly && calcMaxHeight()"
                    [class.disabled]="disabled"
                    [class.loading]="isLoading"
                    [class.readonly]="readonly"
                    class="input"
                    tabindex="0"
            >
                <lta-svg-icon
                        *ngIf="icon && !isLoading"
                        [asCanvas]="iconOptions?.asCanvas !== undefined ? iconOptions?.asCanvas : true"
                        [height]="iconOptions?.height"
                        [icon]="icon"
                        [size]="iconOptions ? null : 20"
                        [style.color]="iconOptions?.color"
                        [style.transform]="iconOptions?.rotation"
                        [width]="iconOptions?.width"
                        class="icon"
                >
                </lta-svg-icon>
                <div
                        *ngIf="currOption?.name || currOption?.date || placeholder"
                        [class.placeholder]="!isValueSelected() || isLoading"
                        [class.select__multi]="multiSelect"
                        class="select__value"
                >
                    <ng-container *ngIf="!multiSelect">
                        {{
                            isLoading
                                    ? ("common.loading" | translate)
                                    : isValueSelected() && !isShowValue
                                            ? ""
                                            : isValueSelected()
                                                    ? currOption.name
                                                    : readonly ? '' : placeholder
                        }}
                        <div
                                *ngIf="currOption?.date?.from && currOption?.date?.to"
                                [class.placeholder]="isShowValue"
                                class="select__date"
                        >
                            <span>{{ currOption.date.from }}</span>
                            <lta-svg-icon
                                    [asCanvas]="true"
                                    class="icon"
                                    icon="arrow-right"
                                    size="20"
                            > </lta-svg-icon>
                            <span>{{ currOption.date.to }}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="multiSelect && currOptions?.length">
                        <span
                                *ngFor="let opt of currOptions"
                                class="multi-option"
                        >
                            {{ opt.name }}
                            <lta-svg-icon
                                    (click)="$event.stopPropagation(); optionSelect(opt)"
                                    [asCanvas]="true"
                                    class="toggler"
                                    icon="close"
                                    size="20"
                            >
                </lta-svg-icon></span>
                    </ng-container>
                </div>
                <lta-svg-icon
                        (click)="$event.stopPropagation(); resetValue()"
                        *ngIf="!isLoading && withCancel && currOption && !readonly"
                        [asCanvas]="true"
                        class="toggler"
                        icon="close"
                        size="20"
                >
                </lta-svg-icon>
                <lta-svg-icon
                        *ngIf="
                        withCancel
                            ? !currOption && !isLoading && !readonly
                            : selectOptions?.length && !isLoading && !disabled && !readonly
                    "
                        [asCanvas]="true"
                        class="toggler"
                        icon="down"
                        size="20"
                >
                </lta-svg-icon>
                <lta-svg-icon
                        *ngIf="isLoading"
                        [asCanvas]="true"
                        class="loader"
                        icon="loading"
                        size="20"
                >
                </lta-svg-icon>
                <span
                        *ngIf="currOption?.extra?.showBackToRange"
                        class="return-to-range"
                >
                    <span
                            class="return-to-range__tooltip"
                            translate
                    >time.backToPrevPeriod</span>
                    <lta-svg-icon
                            (click)="$event.stopPropagation(); onClickReturn.emit()"
                            *ngIf="!isLoading"
                            icon="return"
                            size="15"
                    >
                </lta-svg-icon>
                </span>
            </span>
            <span
                    *ngIf="expanded && selectOptions?.length && !isLoading"
                    [ngClass]="menuPosition"
                    [style.maxHeight]="maxHeight"
                    class="select-menu"
            >
                <span>
                    <span
                            #selections
                    >
                        <span

                                (click)="$event.stopPropagation(); !option.disabled && optionSelect(option)"
                                *ngFor="let option of selectOptions; trackBy: trackBy; let i = index"
                                [class.invisible]="!option.visibility"
                                [class.selected]="isOptionSelected(option)"
                                [ngClass]="{ onlyIcon: option.iconOptions?.position === 'center', disabled: option.disabled }"
                                class="select-menu__item"
                        >
                            <lta-svg-icon
                                    *ngIf="theme === 'alert-dark'"
                                    [asCanvas]="!!hideAfterSelect"
                                    [class.checked]="isOptionSelected(option)"
                                    [class.unchecked]="!isOptionSelected(option)"
                                    [height]="20"
                                    [icon]="isOptionSelected(option) ? 'checkbox-circle-checked' : 'checkbox-circle-unchecked'"
                                    [width]="21"
                                    class="icon select-icon"
                            >
                            </lta-svg-icon>
                            <lta-svg-icon
                                    *ngIf="option.icon"
                                    [asCanvas]="true"
                                    [height]="option.iconOptions?.height"
                                    [icon]="option.icon"
                                    [size]="option.iconOptions ? null : 20"
                                    [style.color]="option.iconOptions?.color"
                                    [style.transform]="option.iconOptions?.rotation"
                                    [width]="option.iconOptions?.width"
                                    class="icon"
                            >
                            </lta-svg-icon>
                            <span class="select-menu__item-text">
                                {{ option.name }}
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </span>
</div>
