import {Injectable} from "@angular/core"
import {CanActivate, UrlTree} from "@angular/router"
import {Observable} from "rxjs"
import {AuthService} from "../services/auth.service"
import {UrlService} from "@atl/shared/services/url.service";

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(
        private urlService: UrlService,
        private authService: AuthService,
    ) {
    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isLoggedIn()) {
            this.urlService.goToAdmin()
        }
        return true
    }
}
