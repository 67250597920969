import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationExtras, Router, UrlSerializer} from "@angular/router";
import {ADMIN_TREE, IUrlOptions, LOGIN_TREE, SETTINGS_TREE, UrlTarget} from "@atl/shared/interfaces/url.interfaces";
import {filter, first, map, tap} from "rxjs/operators";
import {BehaviorSubject, forkJoin} from 'rxjs';
import {SettingsService} from "@atl/admin/settings/services";
import {main} from "@atl/admin/settings/components/main-settings/main-settings.component";
import {UserService} from "@atl/authorization/services/user.service";
import {skipNull} from "@atl/shared/utils";

@Injectable({
    providedIn: 'root'
})
export class UrlService {
    public defaultStartScreen$ = forkJoin([this.settingsService.getSettings(), this.userService.user$.pipe(skipNull(), first())])
        .pipe(
            map(([settings, user]) => {
                    const userVS = user.video_screen_id
                    const roleVS = user.role.video_screen_id
                    const globalVS = Number(settings.find(item => item.name === main.START_SCREEN)?.value) || 1
                    return userVS || roleVS || globalVS
                },
            ))
    public readonly HMI_ROOT = 'hmi'
    public readonly ALERTS_ROOT = 'events'
    public readonly TRENDS_ROOT = 'trends'
    public readonly TABLE_ROOT = 'table'
    public readonly ADMIN_ROOT = 'conf'
    public readonly SETTINGS_ROOT = 'settings'
    public readonly MODELS_ROOT = 'models'
    public readonly LOGIN_ROOT = 'login'
    public readonly NOT_FOUND_ROOT = 'not-found'
    public readonly VNC_ROOT = 'vnc'
    private backHistory: string[] = [];
    private forwardHistory: string[] = [];
    private currentPath: string = ''
    private isGoBack = false
    private isGoForward = false
    private canGoBackSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public canGoBack$ = this.canGoBackSubject.asObservable();
    private canGoForwardSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public canGoForward$ = this.canGoForwardSubject.asObservable();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private serializer: UrlSerializer,
        private settingsService: SettingsService,
        private userService: UserService,
    ) {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            tap((event: NavigationEnd) => {
                if (!this.currentPath) {
                    this.currentPath = event.urlAfterRedirects
                } else if (this.isGoBack) {
                    this.currentPath = event.urlAfterRedirects
                    this.isGoBack = false
                } else if (this.isGoForward) {
                    this.currentPath = event.urlAfterRedirects
                    this.isGoForward = false
                } else {
                    const currentPathSplit = this.currentPath.split('?')
                    const backHistoryLastChildSplit = this.backHistory[this.backHistory.length - 1]?.split('?')

                    this.forwardHistory = []
                    backHistoryLastChildSplit && currentPathSplit[0] === backHistoryLastChildSplit[0]
                        ? this.backHistory[this.backHistory.length - 1] = this.currentPath
                        : this.backHistory.push(this.currentPath)

                    this.currentPath = event.urlAfterRedirects

                    if (this.currentPath === '/') {
                        this.goToHmi()
                        this.backHistory = []
                    }

                    this.backHistory = this.removeUrlFromHistory(this.backHistory, '/')
                    this.backHistory = this.removeUrlFromHistory(this.backHistory, `/${this.LOGIN_ROOT}`)
                    this.backHistory = this.removeUrlFromHistory(this.backHistory, `/${this.NOT_FOUND_ROOT}`)
                }
                this.canGoBackSubject.next(!this.backHistory.length);
                this.canGoForwardSubject.next(!this.forwardHistory.length);
            })).subscribe()
    }

    public getParamsSnapshot() {
        return this.route.snapshot.queryParams
    }

    public getCurrentUrl() {
        const url = this.router.getCurrentNavigation()?.extractedUrl
        return url ? this.serializer.serialize(url) : this.router.url
    }

    public goToAdmin(options?: IUrlOptions<ADMIN_TREE>) {
        const url = [this.ADMIN_ROOT]
        options?.branch && url.push(options?.branch)
        options?.id && url.push(options?.id.toString())
        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
    }

    public goToSettings(options?: IUrlOptions<SETTINGS_TREE>) {
        const url = [this.ADMIN_ROOT, this.SETTINGS_ROOT]
        options?.branch && url.push(options?.branch)

        if (this.currentPath === `/${this.VNC_ROOT}`) {
            this.goBack()
        }

        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
    }

    public goToVnc() {
        const url = [this.VNC_ROOT]
        this.goTo(url, {})
    }

    public goToLogin(options?: IUrlOptions<LOGIN_TREE>) {
        const url = [this.LOGIN_ROOT]
        options?.branch && url.push(options?.branch)
        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
    }

    public navigateByUrl(url: string) {
        this.router.navigateByUrl(url)
    }

    public goToHmi(options?: IUrlOptions<null>) {
        const url = [this.HMI_ROOT]
        if (!options) {
            this.defaultStartScreen$
                .pipe(first())
                .subscribe({
                    next: (screen) => {
                        url.push(screen.toString())
                        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
                    },
                    error: () => {
                        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
                    }
                })

        } else {
            options?.id && url.push(options.id.toString())
            this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
        }
    }

    public goToTrends(options?: IUrlOptions<null>) {
        const url = [this.TRENDS_ROOT]
        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
    }

    public goToTable(options?: IUrlOptions<null>) {
        const url = [this.TABLE_ROOT]
        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
    }

    public goToAlerts(options?: IUrlOptions<null>) {
        const url = [this.ALERTS_ROOT]
        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
    }

    public goToModels(options?: IUrlOptions<null>) {
        const url = [this.ADMIN_ROOT, this.MODELS_ROOT]
        this.goTo(url, {target: options?.target, queryParams: options?.queryParams})
    }

    public addQueryParams(queryParams: Object, extra: NavigationExtras = {}) {
        this.router.navigate([], {queryParams, ...extra, queryParamsHandling: 'merge'})
    }

    public goBackRoute(route: ActivatedRoute) {
        const backRoute = route.snapshot.data.back
        this.router.navigate([backRoute ? backRoute : ".."], {relativeTo: route});
    }

    public goBack() {
        if (this.backHistory.length > 0) {
            this.isGoBack = true

            if (this.currentPath.split('?')[0] === this.backHistory[this.backHistory.length - 1].split('?')[0]) {
                this.backHistory.pop()
            }

            const backUrl = this.backHistory.pop()
            const queryParams = this.extractQueryParams(backUrl)

            const backUrlRoot = backUrl.split('?')[0]

            this.forwardHistory.unshift(this.currentPath)
            this.goTo([backUrlRoot], {target: '_self', queryParams})
        }
    }

    public goForward(): void {
        if (this.forwardHistory.length > 0) {
            this.isGoForward = true

            const forwardUrl = this.forwardHistory.shift()
            const queryParams = this.extractQueryParams(forwardUrl);

            const forwardUrlRoot = forwardUrl.split('?')[0]

            this.backHistory.push(this.currentPath)
            this.goTo([forwardUrlRoot], {target: '_self', queryParams});
        }
    }

    private goTo(url: string[], {target, queryParams}: { target?: UrlTarget, queryParams?: Object }) {
        const flatUrl = [].concat.apply([], url.map(str => str.split('/')));

        if (target === '_blank') {
            window.open(this.router.serializeUrl(
                this.router.createUrlTree(flatUrl, {queryParams})
            ), '_blank');
        } else {
            this.router.navigate(flatUrl, {queryParams})
        }
    }

    private removeUrlFromHistory(history: string[], urlToRemove: string): string[] {
        const index = history.indexOf(urlToRemove);

        if (index >= 0) {
            return history.slice(index + 1);
        }
        return history;
    }

    private extractQueryParams(url: string): Object {
        const queryParams: Object = {};

        const splitUrl = url.split('?');
        if (splitUrl.length > 1) {
            const queryString = splitUrl[1];
            const searchParams = new URLSearchParams(queryString);

            searchParams.forEach((value, param) => {
                queryParams[param] = value;
            })
        }

        return queryParams;
    }
}
