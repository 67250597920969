import {Directive, ElementRef, HostListener, Input, OnDestroy} from '@angular/core';

@Directive({
    selector: '[lta-tooltip]',

})
export class TooltipDirective implements OnDestroy {

    @Input('lta-tooltip') tooltip = '';
    @Input() delay? = 100;
    @Input() allowed? = true;
    @Input() position?: 'left' | 'right' | 'down' | 'top' | 'top-left' | 'default' = 'right';
    @Input() tooltipClasses: string[] = []
    @Input() width: string

    private myPopup;
    private timer;
    private removePopupTimer;


    constructor(private el: ElementRef) {
    }

    ngOnDestroy(): void {
        if (this.myPopup) this.myPopup.remove();
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (this.allowed) {
            if (this.myPopup) this.myPopup.remove();
            this.timer = setTimeout(() => {
                const elementPosition = this.el.nativeElement.getBoundingClientRect();
                const elementSize = this.el.nativeElement;
                let x, y;
                switch (this.position) {
                    case "left": {
                        x = elementPosition.left - 195 + elementSize.offsetWidth - 5;
                        y = elementPosition.top - 20;
                        break;
                    }

                    case "right": {
                        x = elementPosition.left + elementSize.offsetWidth + 5;
                        y = elementPosition.top + elementSize.offsetHeight / 4;
                        break;
                    }

                    case "down": {
                        x = elementPosition.left + elementSize.offsetWidth / 2;
                        y = elementPosition.top + elementSize.offsetHeight + 5;
                        break;
                    }

                    case "top": {
                        x = elementPosition.left + elementSize.offsetWidth / 2;
                        y = elementPosition.top - elementSize.offsetHeight - 5;
                        break;
                    }

                    case "top-left": {
                        x = elementPosition.left + elementSize.offsetWidth + 5;
                        y = elementPosition.top - elementSize.offsetHeight * 2 - 16;
                        break;
                    }
                }
                this.createTooltipPopup(x, y);
            }, this.delay)
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.timer) clearTimeout(this.timer);
        if (this.myPopup) this.myPopup.remove();
        if (this.removePopupTimer) clearTimeout(this.removePopupTimer)
    }

    @HostListener('click') onMouseClick() {
        if (this.timer) clearTimeout(this.timer);
        if (this.removePopupTimer) clearTimeout(this.removePopupTimer)
        if (this.myPopup) this.myPopup.remove();
    }

    private createTooltipPopup(x: number, y: number) {
        if (this.myPopup) this.myPopup.remove();
        let popup = document.createElement('div');
        popup.innerHTML = this.tooltip;
        popup.setAttribute("class", "tooltip-container");
        popup.classList.add(...this.tooltipClasses)
        popup.style.top = y.toString() + "px";
        popup.style.left = x.toString() + "px";
        popup.style.zIndex = '99999';
        if (this.width) popup.style.width = this.width;
        document.body.appendChild(popup);
        if (this.position === 'down' || this.position === 'top') {
            popup.style.left = (x - popup.clientWidth / 2).toString() + "px";
        } else if (this.position === 'top-left') {
            popup.style.left = (x - popup.clientWidth).toString() + "px";
        }
        this.myPopup = popup;
        this.removePopupTimer = setTimeout(() => {
            if (this.myPopup) this.myPopup.remove();
        }, 5000);
    }
}
