import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError, timer} from 'rxjs';
import {catchError, mergeMap, retryWhen} from "rxjs/operators";
import {TranslatePipe} from "@ngx-translate/core";
import {FlashMessagesService} from "@atl/modules/flash-messages/services/flash-messages.service";

export const genericRetryStrategy = ({
                                         maxRetryAttempts = 3,
                                         scalingDuration = 1000,
                                         excludedStatusCodes = []
                                     }: {
    maxRetryAttempts?: number,
    scalingDuration?: number,
    excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            if (
                retryAttempt > maxRetryAttempts ||
                !excludedStatusCodes.find(e => e === error.status)
            ) {
                return throwError(error);
            }
            return timer(retryAttempt * scalingDuration);
        }),
    );
};

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private flashMessagesService: FlashMessagesService, private translatePipe: TranslatePipe) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.has(InterceptorSkipHeader)) {
            const headers = request.headers.delete(InterceptorSkipHeader);
            return next.handle(request.clone({headers}));
        }

        return next.handle(request)
            .pipe(
                retryWhen(genericRetryStrategy({excludedStatusCodes: [500, 502, 504]})),
                catchError((error: HttpErrorResponse) => {
                    let err;
                    if (error.error instanceof ErrorEvent) {
                        err = error.error
                    } else {
                        err = error
                    }
                    const HANDLED_ERRORS = [401, 502, 504]
                    if (!HANDLED_ERRORS.includes(err?.status) && error.error !== "No such token") {
                        this.flashMessagesService.showError(err)
                    }

                    switch (err?.status) {
                        case 502: {
                            const err = {message: this.translatePipe.transform('lta-errors.serverUnavailable')}
                            this.flashMessagesService.showError(err)
                            throw err
                        }

                        case 504: {
                            const err = {message: this.translatePipe.transform('lta-errors.timeout')}
                            this.flashMessagesService.showError(err)
                            throw err
                        }
                    }

                    throw error
                })
            )
    }
}
