import {IEvent} from '../main/alerts/interfaces/events-interfaces'

export type WsRequestMessageType = RequestValueMessage | RequestEventsMessage

export interface RequestValueMessage {
    value_command: {
        with_passes?: boolean;
        command: `subscribe` | `unsubscribe` | `get`,
        objects_list: Array<number>
    }
}

export interface RequestEventsMessage {
    event_command: {
        command: `subscribe` | `unsubscribe`
    }
}

export type WsResponseMessageType = ValueWebsocketMessage | EventWebsocketMessage

export function isResponseMessageType(object: any): object is WsResponseMessageType {
    return 'msg_type' in object;
}

export type WsMessageType = WsRequestMessageType | WsResponseMessageType;

export interface WebSocketConfig {
    url: string;
    reconnectInterval?: number;
    reconnectAttempts?: number;
}

export interface ValueWebsocketMessage {
    msg_type: 0,
    val_msg: ValueType[]
}

export type ValueType = ValueBoolean | ValueFloat | ValueString

export interface AbstractValue {
    id: number,
    val_type: number,
    value_empty: boolean,
    time: number,
    owner: number,
    status_code?: number
}

export interface ValueBoolean extends AbstractValue {
    value_bool: boolean
}

export function isValueBoolean(object: any): object is ValueBoolean {
    return 'value_bool' in object;
}

export interface ValueFloat extends AbstractValue {
    value_float64: number
}

export function isValueFloat(object: any): object is ValueFloat {
    return 'value_float64' in object;
}

export interface ValueString extends AbstractValue {
    value_string: string
}

export function isValueString(object: any): object is ValueString {
    return 'value_string' in object;
}

export interface EventWebsocketMessage {
    msg_type: 1
    ev_msg: IEvent[]
}

export interface ValueWebsocket {
    value: string | number | boolean,
    isReliable: boolean
}
