import {Pipe, PipeTransform} from '@angular/core';
import {isString} from "lodash";

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform<T>(values: T[], fieldPath: string = 'name'): T[] {
        if (!values) return values
        const pathToName = fieldPath.split('.')
        return values.sort((a, b) => {
            let item1 = a
            let item2 = b
            pathToName.forEach(path => {
                item1 = item1[path]
                item2 = item2[path]
            })
            if (isString(item1)) {
                // @ts-ignore
                item1 = item1.toLowerCase()
                // @ts-ignore
                item2 = item2.toLowerCase()
            }
            if (item1 < item2) return -1
            if (item1 > item2) return 1
            return 0;
        });
    }
}
