import {Injectable} from "@angular/core"
import {IUser} from "@app/@atl/administration/users/interfaces"
import {LocalStorageService} from "@app/@atl/shared/services"
import {BehaviorSubject, Observable, of, Subject} from "rxjs"
import {catchError, filter, switchMap, tap} from "rxjs/operators"
import {AuthCredentials, AuthTokens, RefreshmentStatus} from "../interfaces"
import {AuthHttpService} from "./auth-http.service"
import {UrlService} from "@atl/shared/services/url.service";
import {RETURN_URL_QUERY_PARAM} from "@atl/authorization/guards";
import {UserService} from "@atl/authorization/services/user.service";
import {FlashMessagesService} from "@atl/modules/flash-messages/services/flash-messages.service";

export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const REFRESHMENT_STATUS = 'REFRESHMENT_STATUS'

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private errorSubject: Subject<string> = new Subject<string>()
    public error$: Observable<string> = this.errorSubject.asObservable()

    private isRefreshmentFinishedSubject: BehaviorSubject<AuthTokens> = new BehaviorSubject<AuthTokens>(null)
    public isRefreshmentFinished$: Observable<AuthTokens> = this.isRefreshmentFinishedSubject.asObservable()
        .pipe(
            filter(value => value !== null),
        )

    constructor(
        private authHttp: AuthHttpService,
        private storage: LocalStorageService,
        private urlService: UrlService,
        private userService: UserService,
        private flashMessagesService: FlashMessagesService
    ) {
    }

    public isLoggedIn(): boolean {
        const {access_token, refresh_token} = this.getTokens()
        return (access_token && refresh_token && true) || false
    }

    public login(credentials: AuthCredentials): Observable<IUser> {
        return this.authHttp.login(credentials)
            .pipe(
                switchMap(tokens => this.loginUser(tokens)),
                catchError(error => {
                    if (error.error === 'No such user or wrong password') {
                        this.errorSubject.next('auth.invalidUsername')
                    } else {
                        this.errorSubject.next('lta-errors.serverUnavailable')
                    }

                    return of(null)
                }),
            )
    }

    public logout(saveReturnUrl = false): Observable<null> {
        this.flashMessagesService.clearMessages()
        return this.authHttp.logout(this.getTokens())
            .pipe(
                tap(() => {
                    this.localLogout(saveReturnUrl)
                }),
                catchError(error => {
                    this.localLogout()
                    return of(null)
                })
            )
    }

    public refreshTokens(): Observable<AuthTokens> {
        const tokens = this.getTokens()
        return this.authHttp.refreshTokens(tokens)
            .pipe(
                tap((tokens) => {
                    this.storeTokens(tokens)
                }),
            )
    }

    public getTokens(): AuthTokens {
        return {
            access_token: this.storage.getItem(ACCESS_TOKEN, true),
            refresh_token: this.storage.getItem(REFRESH_TOKEN, true),
        } as AuthTokens
    }

    public localLogout(saveReturnUrl = false) {
        this.userService.setUser(null)
        this.removeTokens()
        this.urlService.goToLogin({queryParams: {[RETURN_URL_QUERY_PARAM]: saveReturnUrl ? this.urlService.getCurrentUrl() : null}})
    }

    public initStorageListener() {
        this.storage.initTokenStatus();
        window.addEventListener('storage', (event: StorageEvent) => {
            if (event.key === LocalStorageService.NEW_REFRESHMENT_TOKENS_KEY && event.newValue !== null) {
                let tokens = JSON.parse(event.newValue)
                if (tokens !== null) {
                    this.storeTokens(tokens)
                    this.isRefreshmentFinishedSubject.next(tokens)
                } else {
                    tokens = this.getTokens()
                    this.isRefreshmentFinishedSubject.next(tokens)
                }
                setTimeout(() => {
                    this.isRefreshmentFinishedSubject.next(null)
                }, 5000)

            }

            if (event.key === LocalStorageService.NEW_LOGOUT_REQUEST_KEY && event.newValue !== null) {
                this.logout().subscribe()
            }
        })
    }

    public sendTokensToOtherTabs(tokens: AuthTokens) {
        this.storage.setItemToEmit(LocalStorageService.NEW_REFRESHMENT_TOKENS_KEY, tokens)
        this.isRefreshmentFinishedSubject.next(tokens)
        setTimeout(() => {
            this.isRefreshmentFinishedSubject.next(null)
        }, 5000)
    }

    public sendLogoutRequestToOtherTabs() {
        console.log('sendLogoutRequestToOtherTabs')
        this.storage.setItemToEmit(LocalStorageService.NEW_LOGOUT_REQUEST_KEY, 'logout request')
    }

    public setRefreshmentStatus(status: number) {
        this.storage.setLocalStorageItem(LocalStorageService.REFRESHMENT_STATUS_KEY, status)
    }

    public setRefreshmentId(id: number) {
        this.storage.setLocalStorageItem(LocalStorageService.REFRESHMENT_ID_KEY, id)
    }

    public getRefreshmentStatus(): number {
        return this.storage.getsetLocalStorageItem(LocalStorageService.REFRESHMENT_STATUS_KEY)
    }

    public getRefreshmentId(): number {
        return this.storage.getsetLocalStorageItem(LocalStorageService.REFRESHMENT_ID_KEY)
    }

    public clearValidationError() {
        this.errorSubject.next('')
    }

    private loginUser(tokens: AuthTokens): Observable<IUser> {
        this.storeTokens(tokens)
        this.storage.setItem(REFRESHMENT_STATUS, RefreshmentStatus.False)
        this.flashMessagesService.removeMessageByError('No such user or wrong password')
        return this.userService.requestCurrentUser()
            .pipe(
                switchMap((user) => {
                    const returnUrl = this.urlService.getParamsSnapshot()[RETURN_URL_QUERY_PARAM]
                    if (returnUrl) {
                        this.urlService.navigateByUrl(returnUrl)
                        return of(user)
                    }
                    this.urlService.goToHmi(user.video_screen_id ? {id: user.video_screen_id} : undefined)
                    return of(user)
                })
            )
    }

    private storeTokens(tokens: AuthTokens) {
        this.storage.setItem(ACCESS_TOKEN, tokens.access_token)
        this.storage.setItem(REFRESH_TOKEN, tokens.refresh_token)
    }

    private removeTokens() {
        this.storage.setItem(ACCESS_TOKEN, null)
        this.storage.setItem(REFRESH_TOKEN, null)
    }
}
