export interface AuthCredentials {
    login: string
    password: string
    type_entry_window: 0 | 1 | 2
}

export interface AuthTokens {
    access_token: string
    refresh_token: string
}

export enum RefreshmentStatus {
    False,
    Active,
    Error
}
