import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Component({
    selector: 'lta-modal-layout',
    templateUrl: 'modal-layout.component.html',
    styleUrls: ['modal-layout.component.scss']
})
export class ModalLayoutComponent implements OnInit {
    @Output() layoutClick: EventEmitter<PointerEvent> = new EventEmitter<PointerEvent>();

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        this.layoutClick.emit(event);
    }

    @Output() escapePress: EventEmitter<void> = new EventEmitter<void>();

    @HostListener('document:keydown.escape', ['$event'])
    onEscapePress(event: KeyboardEvent) {
        this.escapePress.emit()
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
