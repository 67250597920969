import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {BtnsState} from "@atl/modules/button/types/save-btn-state.type";

@UntilDestroy()
@Component({
    selector: 'lta-save-dialog',
    templateUrl: 'save-dialog.component.html',
    styleUrls: ['save-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveDialogComponent implements OnInit {
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();
    @Output() onDontSave: EventEmitter<void> = new EventEmitter<void>();
    @Input() canSave: boolean = true;
    @Input() state: BtnsState = 'SAVE';

    constructor() {
    }

    ngOnInit(): void {
    }

}
