import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '../../svg-icon/svg-icon.module';
import { BaseModalComponent } from './base-modal.component';



@NgModule({
  declarations: [BaseModalComponent],
  exports: [BaseModalComponent],
  imports: [
    CommonModule,
    SvgIconModule
  ]
})
export class BaseModalModule { }
