import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {SvgService} from '@atl/shared/services';
import {from} from "rxjs";
import {Canvg} from "canvg";
import {filter, first} from "rxjs/operators";

@Component({
    selector: 'lta-svg-icon',
    templateUrl: 'svg-icon.component.html',
    styleUrls: ['svg-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class SvgIconComponent implements AfterViewInit, OnChanges {
    @Input() icon?: string;
    @Input() size?: number;
    @Input() width?: number;
    @Input() height?: number;
    @Input() fill?: string;
    @Input() stroke?: string;

    @Input() classes = 'icon';
    @Input() asCanvas = false;
    @ViewChild('ref') elementRef: ElementRef<SVGElement>
    @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>
    public loaded = false
    private color: string

    constructor(public svg: SvgService, private cd: ChangeDetectorRef) {
        this.svg.spritesReady$.pipe(filter(v => v), first())
            .subscribe(() => {
                this.cd.markForCheck()
            })
    }

    private _offset = {x: 0, y: 0};

    get offset() {
        return this._offset;
    }

    @Input()
    set offset(value: { x?: number, y?: number }) {
        if (value.x || value.y) {
            this._offset = {...this._offset, ...value}
        }
    }

    public getHeight() {
        return this.height ? this.height + 'px' : this.size ? this.size + 'px' : null
    }

    public getWidth() {
        return this.width ? this.width + 'px' : this.size ? this.size + 'px' : null
    }

    ngAfterViewInit() {
        if (!this.asCanvas) return
        this.createCanvasIcon()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.asCanvas || !this.loaded) return
        this.createCanvasIcon()
    }

    private createCanvasIcon() {
        this.cd.detach()
        this.svg.spritesReady$.pipe(filter(v => v), first())
            .subscribe(() => {
                this.loaded = true
                this.color = this.color ? this.color : window.getComputedStyle(this.elementRef.nativeElement).color
                let svg = this.svg.getRawSvg(this.icon)
                svg = svg.replace(/currentColor/g, this.color)
                if (this.getWidth()) {
                    svg = svg.replace(/(width=")(\d+)(")/, '$1' + this.getWidth() + '$3')
                }
                if (this.getHeight()) {
                    svg = svg.replace(/(height=")(\d+)(")/, '$1' + this.getHeight() + '$3')
                }
                const canvas = this.canvas.nativeElement
                const ctx = canvas.getContext('2d');
                from(Canvg.from(ctx, svg))
                    .subscribe(v => {
                        v.render()
                        this.elementRef.nativeElement.remove()
                        this.cd.reattach()
                        this.cd.markForCheck()
                    })
            })
    }
}
