import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from './btn.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';



@NgModule({
  declarations: [BtnComponent],
  exports: [BtnComponent],
  imports: [
    CommonModule,
    SvgIconModule
  ]
})
export class ButtonModule { }
