<svg
    #ref
    [attr.fill]="fill ? fill : null"
    [attr.height]="getHeight()"
    [attr.stroke]="stroke ? stroke : null"
    [attr.width]="getWidth()"
>
    <!--suppress XmlUnboundNsPrefix -->
    <use [attr.height]="getHeight()"
         [attr.width]="getWidth()" 
         [attr.x]="offset.x" 
         [attr.y]="offset.y" 
         attr.xlink:href="{{svg.sprite}}#{{icon}}"></use>
</svg>

<canvas #canvas *ngIf="asCanvas" [hidden]="!loaded"
></canvas>
