import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {BtnSize, BtnTheme} from '@app/@atl/modules/button/btn-params.model';

@Component({
    selector: 'lta-btn',
    templateUrl: 'btn.component.html',
    styleUrls: ['btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BtnComponent implements AfterViewInit {
    @Input() size: BtnSize = 'lg';
    @Input() theme: BtnTheme = 'primary';
    @Input() disabled = false;
    @Input() isLoading = false;
    @Input() btnIcon = false;
    @Input() isLoadingLeft = false;
    @Input() label: string
    @Input() hasTransition: boolean = true

    @ViewChild('btn') btn: ElementRef<HTMLButtonElement>

    @Output() onClick = new EventEmitter<MouseEvent>();

    constructor() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if(this.hasTransition) {
                this.btn.nativeElement.style.transition = 'all .3s ease-in-out'
            }
        })
    }

    click(event: MouseEvent) {
        if (!this.disabled && !this.isLoading) {
            this.onClick.emit(event)
        }
    }
}
