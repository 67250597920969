import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
    IUpdateVSInput,
    IVideoScreen,
    IVideoScreenArgument, IVideoScreenArgumentChild,
    IVideoScreenElement,
    IVideoScreenElementRuntime,
    IVideoScreenRuntime,
    IVideosScreenObject
} from '../interfaces';
import {HmiHttpService} from "@atl/admin/hmi/services/hmi-http.service";
import {tap} from "rxjs/operators";
import {IObject} from "@atl/admin/objects/interfaces";
import {ValueType, WebsocketService} from "@atl/websocket";
import {WebsocketValuesSessionClass} from "@atl/websocket/classes/websocket-values-session.class";
import {cloneDeep, flattenDeep} from "lodash";
import {Types} from "@atl/admin/models/interfaces";

@Injectable()
export class HmiService {
    public valuesObservable$: Observable<ValueType>
    private videoScreensSubject: BehaviorSubject<IVideoScreen[]> = new BehaviorSubject<IVideoScreen[]>([]);
    public videoScreens$: Observable<IVideoScreen[]> = this.videoScreensSubject.asObservable();
    private videoScreenSubject: Subject<IVideoScreen> = new Subject<IVideoScreen>();
    public videoScreen$: Observable<IVideoScreen> = this.videoScreenSubject.asObservable();
    private videoScreenRuntimeSubject: Subject<IVideoScreenRuntime> = new Subject<IVideoScreenRuntime>();
    public videoScreenRuntime$: Observable<IVideoScreenRuntime> = this.videoScreenRuntimeSubject.asObservable();

    private valuesSession: WebsocketValuesSessionClass

    constructor(private hmiHttpService: HmiHttpService, private websocketService: WebsocketService) {
        this.valuesSession = this.websocketService.startValuesSession([], true)
        this.valuesObservable$ = this.valuesSession.valuesObservable$
    }

    static deconstructVideoScreenObject(obj: IVideosScreenObject): IVideosScreenObject[] {
        if (!obj) return []
        const copy = cloneDeep(obj)
        const extractChildren = (obj: IVideosScreenObject): IVideosScreenObject[] => {
            if (!obj?.children) return [obj]
            const children = [...obj.children]
            delete obj.children
            return [obj, ...flattenDeep(children.map(obj => extractChildren(obj)))]
        }
        return extractChildren(copy).filter(obj => obj.type_id !== Types.Dir)
    }

    public createVideoScreen(videoScreen: IVideoScreen): Observable<IVideoScreen> {
        return this.hmiHttpService.createVideoScreen(videoScreen);
    }

    public subscribeVS(vs: IVideoScreenRuntime) {
        const objectsIds = this.getAllObjectsIds(vs.elements)
        this.valuesSession.addSubscriptions(objectsIds)
    }

    public unsubscribeVS(vs: IVideoScreenRuntime) {
        const objectsIds = this.getAllObjectsIds(vs.elements)

        this.valuesSession.removeSubscription(objectsIds)
    }

    public getVideoScreens(): Observable<IVideoScreen[]> {
        return this.hmiHttpService.getVideoScreens().pipe(tap(v => {
            this.videoScreensSubject.next(v)
        }))
    }

    public getVideoScreensWithoutSvg(): Observable<IVideoScreen[]> {
        return this.hmiHttpService.getVideoScreensWithoutSvg().pipe(tap(v => {
            this.videoScreensSubject.next(v)
        }))
    }

    public getVideoScreenById(id: number): Observable<IVideoScreen> {
        return this.hmiHttpService
            .getVideoScreenById(id)
            .pipe(
                tap((vs) => {
                    this.videoScreenSubject.next(vs)
                    if (!this.videoScreensSubject.value) return;
                    const updatedVSs = this.videoScreensSubject.value.map(v => {
                        if (v.id !== id) return v
                        return vs
                    })
                    this.videoScreensSubject.next(updatedVSs)
                })
            )

    }

    public getVideoScreenRuntimeById(id: number, args?: number[]): Observable<IVideoScreenRuntime> {
        return this.hmiHttpService
            .getVideoScreenRuntimeById(id, args).pipe(tap((vs) => {
                this.videoScreenRuntimeSubject.next(vs)
            }))
    }

    public bindElementToObject(
        element: IVideoScreenElement,
        object: IObject
    ): Observable<IVideoScreenElement> {
        return this.hmiHttpService.bindElementToObject(element, object)
    }

    public bindElementToArgument(
        element: IVideoScreenElement,
        argument: IVideoScreenArgument
    ): Observable<IVideoScreenElement> {
        return this.hmiHttpService.bindElementToArgument(element, argument)
    }

    public bindElementToArgumentChild(
        element: IVideoScreenElement,
        argument: IVideoScreenArgumentChild
    ): Observable<IVideoScreenElement> {
        return this.hmiHttpService.bindElementToArgumentChild(element, argument)
    }

    public unbindElement(element: IVideoScreenElement): Observable<any> {
        return this.hmiHttpService.unbindElement(element)
    }

    public updateVideoScreenElement(id: number, obj: IVideoScreenElement): Observable<IVideoScreenElement> {
        return this.hmiHttpService.updateVideoScreenElement(id, obj)
    }

    public updateVideoScreen(id: number, body: IUpdateVSInput): Observable<IVideoScreen> {
        return this.hmiHttpService.updateVideoScreen(id, body)
    }

    public addVideoScreenArgument(id: number, body: { model: number }): Observable<IVideoScreenArgument> {
        return this.hmiHttpService.addVideoScreenArgument(id, body)
    }

    public deleteVideoScreenArgument(id: number, argumentId: number): Observable<null> {
        return this.hmiHttpService.deleteVideoScreenArgument(id, argumentId)
    }

    public deleteVideoScreen(id: number): Observable<null> {
        return this.hmiHttpService.deleteVideoScreen(id)
            .pipe(tap(x => {
                this.videoScreensSubject.next(this.videoScreensSubject.value.filter(vs => vs.id !== id))
            }))
    }

    private getAllObjectsIds(elements: IVideoScreenElementRuntime[]) {
        if (!elements) return []
        const allObjectsIds = new Set<number>()
        elements.forEach(el => HmiService.deconstructVideoScreenObject(el.object)
            .forEach(obj => allObjectsIds.add(obj.id)))

        return Array.from(allObjectsIds)
    }
}
