import {Injectable} from '@angular/core';
import {CanLoad} from '@angular/router';
import {UrlService} from "@atl/shared/services/url.service"
import {AuthService} from '../services/auth.service';

export const RETURN_URL_QUERY_PARAM = 'returnUrl'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanLoad {

    constructor(private authService: AuthService, private urlService: UrlService) {
    }

    canLoad() {
        if (!this.authService.isLoggedIn()) {
            this.urlService.goToLogin({queryParams: {[RETURN_URL_QUERY_PARAM]: this.urlService.getCurrentUrl()}})
        }
        return true
    }
}
