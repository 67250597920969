import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {LangService, LocalStorageService} from '@atl/shared/services';
import {TimeService} from "@atl/shared/services/time.service";
import {UserService} from "@atl/authorization/services/user.service";
import {AuthService} from '@atl/authorization/services';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

    constructor(
        private lang: LangService,
        private storage: LocalStorageService,
        private timeService: TimeService,
        private userService: UserService,
        private auth: AuthService
    ) {
    }

    ngOnInit() {
        this.storage.initStorage();
        this.auth.initStorageListener();
        this.lang.initLang();
        this.timeService.initTime().subscribe()
        this.userService.requestCurrentUser().subscribe()
    }
}
