<span
        *ngIf="label"
        [class]="'label'"
>{{ label }}</span>
<button
        #btn
        (click)="click($event)"
        [class.btn-icon]="btnIcon"
        [class.loading]="isLoading"
        [disabled]="disabled"
        [ngClass]="[theme, size]"
        class="btn"
>
    <lta-svg-icon
            *ngIf="isLoading && isLoadingLeft"
            class="loader loader-left"
            icon="loading"
            size="20"
    ></lta-svg-icon>
    <ng-content></ng-content>
    <lta-svg-icon
            *ngIf="isLoading && !isLoadingLeft"
            class="loader loader-right"
            icon="loading"
            size="20"
    ></lta-svg-icon>
</button>
