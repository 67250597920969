import {DriverTreeTypes, IDriverTreeItem} from '@atl/admin/drivers/interfaces';
import {Types} from "@atl/admin/models/interfaces";
import {IObject, IObjectPathItem} from "@atl/admin/objects/interfaces";
import {round} from "lodash";
import {TrendData} from "@atl/main/trends/interfaces/trend-interface";
import {ILtaNodeData, LayoutType} from "@atl/modules/tree/interfaces/tree.interface";

export class TypesUtils {
    static getIconByModelId(type: Types) {
        switch (type) {
            case Types.Bool:
                return 'bool';
            case Types.Float:
                return 'float';
            case Types.String:
                return 'abc';
            case Types.Dir:
                return 'folder';
            case Types.Int:
                return 'integer';
            default:
                return 'composite'
        }
    }

    static getTypeNameByType(type: Types) {
        switch (type) {
            case Types.Dir:
                return 'object';
            case Types.Bool:
                return 'boolean';
            case Types.Float:
                return 'float';
            case Types.String:
                return 'string';
            case Types.Int:
                return 'integer';
        }
    }

    static isDir(type: Types): boolean {
        return type === Types.Dir
    }

    static getIconByDriverTreeType(type: DriverTreeTypes) {
        switch (type) {
            case DriverTreeTypes.SERVER_TYPE_FLOAT64:
                return 'float';
            case DriverTreeTypes.SERVER_TYPE_BOOL:
                return 'bool';
            case DriverTreeTypes.SERVER_TYPE_STRING:
                return 'abc';
            default:
                return 'folder'
        }
    }

    static driverTreeItemToNodeData(driverTreeItem: IDriverTreeItem): ILtaNodeData {
        const treeItem: ILtaNodeData = {
            item: driverTreeItem,
            selectable: true,
            name: driverTreeItem.name,
            hasChildren: driverTreeItem.type === DriverTreeTypes.SERVER_TYPE_UNKNOWN,
            id: driverTreeItem.object_id,
            children: driverTreeItem.childs?.length ? driverTreeItem.childs?.map(d => this.driverTreeItemToNodeData(d)) : null,
            icon: TypesUtils.getIconByDriverTreeType(driverTreeItem.type)
        };

        if (driverTreeItem.mapping) {
            treeItem.useLayout ? treeItem.useLayout.push(LayoutType.DriverMappingLayout) : treeItem.useLayout = [LayoutType.DriverMappingLayout];
        }

        return treeItem;
    }
}

export function isLeftButton(event: MouseEvent | TouchEvent): boolean {
    if (event.type === 'touchstart') {
        return true;
    }
    return (event.type === 'mousedown' && (event as MouseEvent).button === 0);
}

export function getEvent(event: MouseEvent | TouchEvent): MouseEvent | Touch {
    if (event.type === 'touchend' || event.type === 'touchcancel') {
        return (event as TouchEvent).changedTouches[0];
    }
    return event.type.startsWith('touch') ? (event as TouchEvent).targetTouches[0] : event as MouseEvent;
}

export function maxZIndex(selectors: string = 'body *'): number {
    return Array.from(document.querySelectorAll(selectors))
        .map(a => parseFloat(window.getComputedStyle(a).zIndex))
        .filter(a => !isNaN(a))
        .sort((a, b) => a - b)
        .pop() || 0;
}

export function findAncestor(el, selectors): any {
    if (typeof el.closest === 'function') {
        return el.closest(selectors) || null;
    }
    while (el) {
        if (el.matches(selectors)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

export function addTypeToPath(objectPath: IObjectPathItem[], object: IObject): IObjectPathItem[] {
    if (!objectPath || !object) return []
    return objectPath.map(value => {
        if (value.id === object.id) {
            return {
                ...value,
                type: object.type,
                descr: object.descr
            }
        }
        return value
    })
}

export function milliToNanoseconds(milliseconds: number) {
    return milliseconds * 1000000
}

export function nanoToMilliseconds(nanoseconds: number) {
    return round(nanoseconds / 1000000)
}

export function removeNull(obj) {
    // @ts-ignore
    Object
        .entries(obj)
        .forEach(([k, v]) => {
            if (v && typeof v === 'object') {
                removeNull(v);
            }
            if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                if (Array.isArray(obj)) {
                    // @ts-ignore
                    obj.splice(k, 1);
                } else {
                    delete obj[k];
                }
            }
        });
    return obj;
}

export function splitDataByInterval(data: TrendData[], startDate: number, interval: number): TrendData[][] {
    let result = [];
    let currentInterval = [];

    for (let i = 0; i < data.length; i++) {
        const timestamp = data[i].date;

        // Check if the timestamp is within the interval
        if (timestamp >= startDate && timestamp < startDate + interval) {
            currentInterval.push(data[i]);
        } else if (timestamp >= startDate + interval) {
            // Move to the next interval and reset currentInterval
            result.push(currentInterval);
            currentInterval = [data[i]];
            startDate += interval;
        }
    }

    // Add the last interval to the result
    result.push(currentInterval);

    return result;
}

export function filterMinAndMaxData(data: TrendData[]): TrendData[] {
    if (data.length > 1) {
        let min = Math.min(...data.map(item => item.value))
        let max = Math.max(...data.map(item => item.value))
        if (min === max) return [data.find(value => value.value === min)]
        return [...data.filter(item => item.value === min || item.value === max)]
    }
    return data
}

