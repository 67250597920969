import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveDialogComponent } from './save-dialog.component';
import { BaseModalModule } from '../base-modal/base-modal.module';
import { ButtonModule } from '../../button/button.module';
import { SvgIconModule } from '../../svg-icon/svg-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { ModalLayoutModule } from '../modal-layout/modal-layout.module';



@NgModule({
  declarations: [SaveDialogComponent],
  exports: [SaveDialogComponent],
  imports: [
    CommonModule,
    BaseModalModule,
    ButtonModule,
    SvgIconModule,
    TranslateModule,
    ModalLayoutModule
  ]
})
export class SaveDialogModule { }
