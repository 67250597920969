import {Injectable} from '@angular/core';
import { AuthTokens } from '@app/@atl/authorization/interfaces';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    static readonly KEY = 'LTA'
    static readonly REFRESHMENT_STATUS_KEY = 'LTA_REFRESHMENT_STATUS'
    static readonly REFRESHMENT_ID_KEY = 'LTA_REFRESHMENT_ID'
    static readonly NEW_REFRESHMENT_TOKENS_KEY = 'LTA_NEW_REFRESHMENT_TOKENS'
    static readonly NEW_LOGOUT_REQUEST_KEY = 'LTA_NEW_LOGOUT_REQUEST'

    storage;

    constructor() {
    }

    initStorage() {
        if (!localStorage.getItem(LocalStorageService.KEY)) {
            localStorage.setItem(LocalStorageService.KEY, JSON.stringify({}));
        }

        this.storage = JSON.parse(localStorage.getItem(LocalStorageService.KEY));
    }

    initTokenStatus() {
        localStorage.setItem(LocalStorageService.REFRESHMENT_STATUS_KEY, '0')
    }

    getData() {
        return this.storage;
    }

    setData() {
        localStorage.setItem(LocalStorageService.KEY, JSON.stringify(this.storage));
    }

    getItem(key: string, forceUpdate = false) {
        if (forceUpdate) this.initStorage()
        return this.storage[key];
    }

    setItem(key: string, data) {
        this.storage[key] = data;
        this.setData();
    }

    setLocalStorageItem(key: string, status: number) {
        localStorage.setItem(key, String(status))
    }
    getsetLocalStorageItem(key: string) {
        return Number(localStorage.getItem(key))
    }

    setItemToEmit(key: string, tokens: AuthTokens | string) {
        localStorage.setItem(key, JSON.stringify(tokens))
        localStorage.removeItem(key)
    }
}
