import {Inject, Injectable, Injector} from '@angular/core';
import {from, interval, Observable, Observer, of, Subject, SubscriptionLike} from 'rxjs';
import {
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    share,
    switchMap,
    takeUntil,
    takeWhile,
    tap
} from 'rxjs/operators';
import {WebSocketSubject, WebSocketSubjectConfig} from 'rxjs/webSocket';
import {
    EventWebsocketMessage,
    isResponseMessageType,
    RequestEventsMessage,
    RequestValueMessage,
    ValueType,
    ValueWebsocketMessage,
    WebSocketConfig,
    WsMessageType,
    WsRequestMessageType,
    WsResponseMessageType,
} from './websocket.interfaces';
import {config} from './websocket.config';
import {WebsocketValuesSessionClass} from "@atl/websocket/classes/websocket-values-session.class";
import {nanoToMilliseconds} from "@atl/shared/utils";
import {AuthService} from '../authorization/services';
import {UserService} from '../authorization/services/user.service';


@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    public isConnected: boolean;
    public status$: Observable<boolean>;
    private lastValuesMap: Map<number, ValueType> = new Map();
    private config: WebSocketSubjectConfig<WsMessageType>;
    private websocketSub: SubscriptionLike;
    private statusSub: SubscriptionLike;
    private reconnection$: Observable<number>;
    private websocket$: WebSocketSubject<WsMessageType>;
    private connection$: Observer<boolean>;
    private wsMessages$: Subject<WsMessageType>;
    private reconnectInterval: number;
    private reconnectAttempts: number;
    private valueSubscriptionsWithoutPassesCount = new Map<number, number>()
    private valueSubscriptionsWithPassesCount = new Map<number, number>()
    private eventsSubscriptionCount = 0

    constructor(@Inject(config) private wsConfig: WebSocketConfig, private injector: Injector, private authService: AuthService, private user: UserService) {
        this.user.user$.subscribe(user => {
            if (user) {
                this.initWebsocket()
            }
            if (!user && this.websocket$) {
                this.websocket$.complete()
                this.statusSub.unsubscribe()
            }
        })
    }

    static getValue(message: ValueType): boolean | number | string {
        switch (message.val_type) {
            case 1:
                return 'value_bool' in message && !message.value_empty ? message.value_bool : false;
            case 2:
                return 'value_float64' in message && !message.value_empty ? message.value_float64 : 0;
            default:
                return 'value_string' in message && !message.value_empty ? message.value_string : '';
        }
    }

    static getTime(message: ValueType): Date {
        return new Date(nanoToMilliseconds(message.time));
    }

    public createLastValuesMessage(ids: number[]): ValueWebsocketMessage {
        return {
            msg_type: 0,
            val_msg: ids.map(id => this.lastValuesMap.get(id)).filter(v => v !== undefined)
        }
    }

    public startValuesSession(objectIds: number[], withPasses?: boolean): WebsocketValuesSessionClass {
        return new WebsocketValuesSessionClass(this.injector, objectIds, withPasses)
    }

    // Return Observable and unsubscribe function
    public subscribeToValues(objectIds: number[], with_passes?: boolean): [Observable<ValueType>, () => void] {
        const objectsArray = objectIds
        this.addValueSubscriptions(objectsArray, with_passes)

        function unsubscribeFunction() {
            this.removeValueSubscriptions(objectsArray, with_passes)
        }

        return [this.valuesObservable()
            .pipe(
                map(message => {
                    message.val_msg = message.val_msg.filter(value => objectsArray.includes(value.id))
                    return message
                }),
                switchMap(message => from(message.val_msg)
                    .pipe(
                        concatMap(val => of(val)),
                    ))
            ), unsubscribeFunction.bind(this)]
    }

    public subscribeToEvents(): [Observable<EventWebsocketMessage>, () => void] {
        if (this.eventsSubscriptionCount !== 0) {
            this.eventsSubscriptionCount++
        } else {
            this.eventsSubscriptionCount = 1
            const message: RequestEventsMessage = {
                event_command: {
                    command: 'subscribe',
                }
            }
            this.send(message)
        }

        function unsubscribeFunction() {
            if (this.eventsSubscriptionCount === 1) {
                this.eventsSubscriptionCount = 0
                const message: RequestEventsMessage = {
                    event_command: {
                        command: 'unsubscribe'
                    }
                }
                this.send(message)
            } else {
                this.eventsSubscriptionCount--
            }
        }

        return [this.eventsObservable(), unsubscribeFunction.bind(this)]
    }

    public removeValueSubscriptions(objectIds: number[], withPasses: boolean) {
        const toUnsubscribe = []
        const toResubscribeWithPasses = []
        objectIds.forEach(id => {
            const subscriptionsWithPassesNumber = this.valueSubscriptionsWithPassesCount.get(id) || 0
            const subscriptionsWithoutPassesNumber = this.valueSubscriptionsWithoutPassesCount.get(id) || 0
            if (withPasses) {
                if (!subscriptionsWithoutPassesNumber && subscriptionsWithPassesNumber - 1 === 0) {
                    toUnsubscribe.push(id)
                }
                this.valueSubscriptionsWithPassesCount.set(id, subscriptionsWithPassesNumber - 1)
            } else {
                if (!subscriptionsWithPassesNumber && subscriptionsWithoutPassesNumber - 1 === 0) {
                    toUnsubscribe.push(id)
                } else if (subscriptionsWithPassesNumber && subscriptionsWithoutPassesNumber - 1 === 0) {
                    toResubscribeWithPasses.push(id)
                }
                this.valueSubscriptionsWithoutPassesCount.set(id, subscriptionsWithoutPassesNumber - 1)
            }

        })

        if (toUnsubscribe.length) {
            const unsub: RequestValueMessage = {
                value_command: {
                    command: 'unsubscribe',
                    objects_list: toUnsubscribe
                }
            }
            this.send(unsub)
        }
        if (toResubscribeWithPasses.length) {
            const sub: RequestValueMessage = {
                value_command: {
                    command: 'subscribe',
                    objects_list: toResubscribeWithPasses,
                    with_passes: true
                }
            }
            this.send(sub)
        }
    }

    public addValueSubscriptions(objectIds: number[], withPasses: boolean) {
        const toSubscribe: number[] = []
        objectIds.forEach(id => {
            const subscriptionsWithPassesNumber = this.valueSubscriptionsWithPassesCount.get(id) || 0
            const subscriptionsWithoutPassesNumber = this.valueSubscriptionsWithoutPassesCount.get(id) || 0
            if (withPasses) {
                if (subscriptionsWithoutPassesNumber === 0 && subscriptionsWithPassesNumber === 0) {
                    toSubscribe.push(id)
                }
                this.valueSubscriptionsWithPassesCount.set(id, subscriptionsWithPassesNumber + 1)
            } else {
                if (subscriptionsWithoutPassesNumber === 0) {
                    toSubscribe.push(id)
                }
                this.valueSubscriptionsWithoutPassesCount.set(id, subscriptionsWithoutPassesNumber + 1)
            }
        })

        if (toSubscribe.length) {
            const message: RequestValueMessage = {
                value_command: {
                    command: 'subscribe',
                    objects_list: toSubscribe,
                    with_passes: withPasses
                }
            }
            this.send(message)
        }
    }

    public valuesObservable(): Observable<ValueWebsocketMessage> {
        return this.responseMessages()
            .pipe(
                filter((message) => message.msg_type === 0),
                tap((value: ValueWebsocketMessage) => {
                    value.val_msg.forEach(v => {
                        this.lastValuesMap.set(v.id, v)
                    })

                })
            ) as Observable<ValueWebsocketMessage>
    }

    public waitForConnection(cb: Function) {
        if (!this.isConnected) {
            console.warn(`Error WS server not connected.`);
            if (!this.status$) {
                setTimeout(() => {
                    this.waitForConnection(cb)
                })
                return;
            }
            const stop$ = new Subject()
            this.status$.pipe(takeUntil(stop$)).subscribe(isConnected => {
                if (isConnected) {
                    cb()
                    stop$.next(null)
                }
            })
            return;
        } else {
            cb()
        }
    }

    private initWebsocket() {
        this.wsMessages$ = new Subject<WsMessageType>();

        this.reconnectInterval = this.wsConfig.reconnectInterval || 5000; // pause between connections
        this.reconnectAttempts = this.wsConfig.reconnectAttempts || 100; // number of connection attempts
        this.initConfig()

        this.status$ = new Observable<boolean>((observer) => {
            this.connection$ = observer;
        }).pipe(share(), distinctUntilChanged());

        this.statusSub = this.status$
            .subscribe((isConnected) => {
                this.isConnected = isConnected;

                if (!this.reconnection$ && typeof (isConnected) === 'boolean' && !isConnected) {
                    this.reconnect();
                }
            });

        this.websocketSub = this.wsMessages$.subscribe({
            next: null, error: (error: ErrorEvent) => console.error('WebSocket error!', error)
        });

        this.connect();
    }

    private initConfig() {
        const tokens = this.authService.getTokens()
        this.config = {
            url: `${'https:' === location.protocol ? 'wss:' : 'ws:'}//${location.host}/api/v1/ws?lacerta_auth_token=${tokens.access_token}`,
            closeObserver: {
                next: (_event: CloseEvent) => {
                    console.log('WebSocket closed!')
                    this.isConnected = false
                    this.websocketSub.unsubscribe()
                    this.websocket$ = null
                    this.connection$.next(false)
                }
            },
            openObserver: {
                next: (_event: Event) => {
                    console.log('WebSocket connected!');
                    this.connection$.next(true);
                }
            }
        };
    }

    /*
    * on message to server
    * */
    private send(message: WsRequestMessageType): void {
        if (!message) {
            console.error(`Send error, bad Message(${message})`);
            return;
        }

        this.waitForConnection(() => this.websocket$.next(message))
    }

    /*
    * connect to WebSocked
    * */
    private connect(): void {
        this.websocket$ = new WebSocketSubject(this.config);

        this.websocket$.subscribe({
            next: (message) => this.wsMessages$.next(message),
            error: (_error: Event) => {
                if (!this.websocket$) {
                    this.reconnect();
                }
            }
        });
    }

    /*
    * reconnect if not connecting or errors
    * */
    private reconnect(): void {
        // Если вкладка не активна
        if (document.hidden) {
            const recFunc = () => {
                if (!document.hidden) {
                    document.removeEventListener('visibilitychange', recFunc)
                    this.reconnect()
                }
            }

            document.addEventListener('visibilitychange', recFunc);
            return
        }

        this.reconnection$ = interval(this.reconnectInterval)
            .pipe(takeWhile((_v, index) => index < this.reconnectAttempts && !this.websocket$));

        this.reconnection$.subscribe({
            next: () => {
                this.initConfig()
                this.connect()
            },
            error: null,
            complete: () => {
                // Subject complete if reconnect attemts ending
                this.reconnection$ = null;

                if (!this.websocket$) {
                    this.wsMessages$.complete();
                    this.connection$.complete();
                    return;
                }

                if (this.eventsSubscriptionCount > 0) {
                    this.send({
                        event_command: {
                            command: 'subscribe',
                        }
                    })
                }

                this.resubscribeValues()
            }
        });
    }

    private resubscribeValues() {
        const valuesWithPasses = []
        const valuesWithoutPasses = []
        this.valueSubscriptionsWithPassesCount.forEach((subscribers, id) => {
            if (subscribers > 0 && !this.valueSubscriptionsWithoutPassesCount.get(id)) {
                valuesWithPasses.push(id)
            }
        })
        this.valueSubscriptionsWithoutPassesCount.forEach((subscribers, id) => {
            if (subscribers > 0) {
                valuesWithoutPasses.push(id)
            }
        })

        if (valuesWithPasses.length > 0) {
            this.send({
                value_command: {
                    command: 'subscribe',
                    objects_list: valuesWithPasses,
                    with_passes: true
                }
            })
        }

        if (valuesWithoutPasses.length > 0) {
            this.send({
                value_command: {
                    command: 'subscribe',
                    objects_list: valuesWithoutPasses,
                    with_passes: false
                }
            })
        }

    }

    private requestMessages(): Observable<WsRequestMessageType> {
        return this.wsMessages$
            .pipe(
                filter(message => !isResponseMessageType(message))
            ) as Observable<WsRequestMessageType>
    }

    private responseMessages(): Observable<WsResponseMessageType> {
        return this.wsMessages$
            .pipe(
                filter(message => isResponseMessageType(message))
            ) as Observable<WsResponseMessageType>
    }

    /*
    * on message event
    * */
    private on(): Observable<WsResponseMessageType> {
        return this.wsMessages$
            .pipe(
                //filter((message: WsResponseMessageType) => message.command === event),
                map((message: WsResponseMessageType) => message)
            );
    }

    private eventsObservable(): Observable<EventWebsocketMessage> {
        return this.responseMessages()
            .pipe(
                filter((message) => message.msg_type === 1)
            ) as Observable<EventWebsocketMessage>
    }
}
