import {Injector} from "@angular/core";
import {ValueType, WebsocketService} from "@atl/websocket";
import {from, Observable, of, Subject, SubscriptionLike} from "rxjs";
import {concatMap, map, switchMap} from "rxjs/operators";
import {difference} from "lodash";

export class WebsocketValuesSessionClass {
    public valuesObservableSubject = new Subject<ValueType>();
    public valuesObservable$: Observable<ValueType> = this.valuesObservableSubject.asObservable()

    private websocketService: WebsocketService
    private withPasses: boolean
    private subscriptionsCount = new Map<number, number>()
    private sub: SubscriptionLike

    constructor(injector: Injector, objectIds: number[], withPasses?: boolean) {
        this.websocketService = injector.get(WebsocketService)
        this.withPasses = withPasses
        this.addSubscriptions(objectIds)
    }

    public addSubscriptions(objectIds: number[]) {
        if (!objectIds.length) return
        const newObjects = []
        objectIds.forEach(id => {
            const count = this.subscriptionsCount.get(id) || 0
            if (!count) newObjects.push(id)
            this.subscriptionsCount.set(id, count + 1)
        })
        this.websocketService.addValueSubscriptions(newObjects, this.withPasses)
        this.sub?.unsubscribe()
        this.websocketService.createLastValuesMessage(objectIds).val_msg.forEach(v => {
            this.valuesObservableSubject.next(v)
        })
        this.sub = this.websocketService.valuesObservable()
            .pipe(
                map(message => {
                    return message.val_msg.filter(value => Array.from(this.subscriptionsCount.keys()).includes(value.id))
                }),
                switchMap(val => from(val)
                    .pipe(
                        concatMap(val => of(val)),
                    ))
            ).subscribe(v => {
                this.valuesObservableSubject.next(v)
            })
    }

    public removeSubscription(objectIds: number[]) {
        if (!objectIds.length) return
        const toUnsubscribe = []
        objectIds.forEach(id => {
            const count = this.subscriptionsCount.get(id) || 0
            if (!count) {
                this.subscriptionsCount.delete(id)
                return;
            }
            if (count - 1 === 0) {
                toUnsubscribe.push(id)
                this.subscriptionsCount.delete(id)
                return;
            }
            this.subscriptionsCount.set(id, count - 1)

        })
        if (toUnsubscribe.length) {
            this.websocketService.removeValueSubscriptions(toUnsubscribe, this.withPasses)
        }
    }

    public setSubscriptions(objectIds: number[]) {
        const toRemove = difference(Array.from(this.subscriptionsCount.keys()), objectIds)
        const toAdd = difference(objectIds, Array.from(this.subscriptionsCount.keys()))
        toRemove.length && this.removeSubscription(toRemove)
        toAdd.length && this.addSubscriptions(toAdd)
        this.subscriptionsCount.clear()
        objectIds.forEach(id => {
            this.subscriptionsCount.set(id, 1)
        })
    }

    public endSession() {
        this.removeSubscription(Array.from(this.subscriptionsCount.keys()))
    }
}
