import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SvgIconModule} from "@atl/modules/svg-icon/svg-icon.module";
import {FlashMessagesComponent} from "@atl/modules/flash-messages/components";


@NgModule({
    declarations: [
        FlashMessagesComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        SvgIconModule
    ],
    exports: [
        FlashMessagesComponent
    ],
})
export class FlashMessagesModule {
}
